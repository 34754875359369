import React, { PureComponent } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { helpers } from '@common'

import './style.scss'

export const transitionRoute = (WrappedComponent) => {
  return class extends PureComponent {
    constructor() {
      super()
      this.state = {
        show: false,
      }
    }

    componentWillUnmount() {
      this.setState({ show: false })
    }

    componentDidMount() {
      this.setState({ show: true })
    }

    render() {
      return (
        <CSSTransition
          in={this.state.show}
          classNames={{
            enter: 'route-change-enter',
            enterActive: 'route-change-enter-active',
            exit: 'route-change-exit',
            exitActive: 'route-change-exit-active',
          }}
          timeout={300}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <WrappedComponent {...this.props} />
        </CSSTransition>
      )
    }
  }
}
