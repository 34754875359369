import React, { PureComponent, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Clipboard from 'clipboard'
import { goBack } from 'connected-react-router'

import { globalActionCreator } from '@pc/common/globalActions'
import { Tabs } from 'antd-mobile'
import Icon from '@pc/components/Icon'
import { Button } from '@pc/components/Button'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../logger.config'
import BizTracker from '@pc/common/tracker/bizTracker'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import { DynamicClock } from '../../components/DynamicClock'
import { Translate } from '@pc/config/translate'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

const { get } = helpers

@withBasic
@helpers.hot(module)
class PaymentCode extends PureComponent {
  componentDidMount() {
    this.props.enableCopyPaymentCode()
    const saLogger = Logger.getInstance()
    const name = get(this.props, ['location', 'state', 'name'])

    saLogger.onPageView({
      ...loggerConfig.page_view_payment_code,
      Aku_PageStatus: name,
    })
    BizTracker.enter({ sn: 390006 })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  render() {
    const {
      translate,
      formatPrice,
      getPaymentResult,
      togglePaymentGuide,
      copyPaymentCode,
      currencyCode,
      showPaymentGuide,
      reselectPaymentMethod,
    } = this.props
    const {
      payCode,
      expire,
      curPeriod,
      payId,
      amount,
      callbackPageUrl,
      targetName,
      merchantImg,
      popupType,
      nonce,
      sign,
      bizIds,
    } = get(this.props, ['location', 'state'], {})

    console.log(this.props.location.state)

    return (
      <section
        {...className('paymentCode-area', {
          'paymentCode-bank': popupType !== 'code',
        })}
      >
        <div {...className('paymentCode-area-top')}>
          <div {...className('paymentCode-top-banner')}>
            <div {...className('paymentCode-countdown')}>
              <p>{translate('付款倒计时')}</p>
              <DynamicClock type="countdown" endTime={expire} />
            </div>
            <div {...className('paymentCode-icon')}>
              <img src={merchantImg} alt={targetName} />
            </div>
          </div>

          <div {...className('paymentCode-details')}>
            <div>
              <span>{translate('付款码')}</span>
              <span>{payCode}</span>
              <span
                {...className('copy-btn')}
                id="copyPayCode"
                data-clipboard-text={payCode}
              >
                {translate('复制')}
              </span>
            </div>
            <div>
              {popupType === 'code' ? (
                <span>
                  {translate('向${store}支付', {
                    store: targetName,
                  })}
                </span>
              ) : (
                <span>
                  {translate('向${bank}转账', {
                    bank: targetName,
                  })}
                </span>
              )}
              <span>
                {currencyCode}
                {formatPrice(amount)}
              </span>
            </div>
          </div>

          <div {...className('btns-group')}>
            <Button
              text={translate('查询支付结果')}
              {...className('query-result')}
              onClick={() =>
                getPaymentResult({
                  curPeriod,
                  price: amount,
                  callbackPageUrl,
                  payId,
                  sign,
                  nonce,
                  bizIds,
                })
              }
            />

            <Button
              text={translate('选择其他支付方式')}
              type="secondary"
              onClick={() => reselectPaymentMethod()}
            />
          </div>
        </div>

        {popupType === 'code' && (
          <div {...className('paymentCode-area-bottom')}>
            <div {...className('area-title font-lato-bol')}>
              {translate('转账指引')}
            </div>
            <p {...className('guide-note')}>
              {translate(
                '*请记录页面上的虚拟账户以及支付信息，方便操作转账，最好是能够截屏保存。',
              )}
            </p>
            <p
              {...className('guideline', {
                expand: showPaymentGuide,
              })}
              onClick={() => togglePaymentGuide(showPaymentGuide)}
            >
              <span>{translate('查看更多')}>></span>
            </p>

            <div {...className('paymentCode-guide')}>
              <ul
                {...className('paymentCode-guide-list', {
                  hide: !showPaymentGuide,
                })}
              >
                <li>
                  {translate(
                    '1.记录并保存您的Alfamart/Indomaret付款代码，即：${paymentCode}.',
                    {
                      paymentCode: payCode,
                    },
                  )}
                </li>
                <li>
                  {translate(
                    '2.去最近的Alfamart / Indomaret收银台，告诉收银员你想付款“Akulaku”。',
                  )}
                </li>
                <li>
                  {translate(
                    '3.向收银员出示您的Alfamart / Indomaret支付代码，并请支付${amount}.',
                    {
                      amount: `${currencyCode} ${amount}`,
                    },
                  )}
                </li>
                <li>
                  {translate('4.将您的付款收据保存,该收据为合法付款凭证。')}
                </li>
              </ul>
            </div>
          </div>
        )}

        {popupType !== 'code' && (
          <div {...className('paymentCode-area-bottom')}>
            <p
              {...className('guideline-bank font-lato-bol')}
              onClick={() => togglePaymentGuide(showPaymentGuide)}
            >
              <span>{translate('转账指引')}</span>
            </p>

            <div {...className('bankTransfer-guide')}>
              <Tabs
                style={{ lineHeight: 1 }}
                tabs={[
                  { title: 'ATM', width: '20%' },
                  { title: 'Mobile Banking' },
                  { title: 'Internet Banking' },
                ]}
                initialPage={0}
                distanceToChangeTab={0.2}
                tabBarInactiveTextColor="#9C9C9C"
                tabBarActiveTextColor="#111111"
              >
                <div {...className('bankTransfer-guide-content')}>
                  <p {...className('guide-note')}>
                    {translate(
                      '*请记录页面上的虚拟账户以及支付信息，方便操作转账，最好是能够截屏保存。',
                    )}
                  </p>
                  <p
                    {...className('guideline', {
                      expand: showPaymentGuide,
                    })}
                    onClick={() => togglePaymentGuide(showPaymentGuide)}
                  >
                    <span>{translate('查看更多')}>></span>
                  </p>

                  <ul
                    {...className('backTransfer-guide-list', {
                      hide: !showPaymentGuide,
                    })}
                  >
                    <li>{translate('支付指南，')}</li>
                    <li>{translate('选择【付费/购买】菜单，')}</li>
                    <li>{translate('选择【其他】，')}</li>
                    <li>{translate('选择【多付款】，')}</li>
                    <li>{translate('【12345】输入 - 例如，')}</li>
                    <li>{translate('正如【机构代码】，')}</li>
                    <li>
                      {translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}
                    </li>
                    <li>{translate('选择【右】，')}</li>
                    <li>{translate('选择【是】，')}</li>
                    <li>{translate('选择【是】，')}</li>
                    <li>{translate('拿你的付款证明，')}</li>
                    <li>{translate('完')}</li>
                  </ul>
                </div>
                <div {...className('bankTransfer-guide-content')}>
                  <p {...className('guide-note')}>
                    {translate(
                      '*请记录页面上的虚拟账户以及支付信息，方便操作转账，最好是能够截屏保存。',
                    )}
                  </p>
                  <p
                    {...className('guideline', {
                      expand: showPaymentGuide,
                    })}
                    onClick={() => togglePaymentGuide(showPaymentGuide)}
                  >
                    <span>{translate('查看更多')}>></span>
                  </p>

                  <ul
                    {...className('backTransfer-guide-list', {
                      hide: !showPaymentGuide,
                    })}
                  >
                    <li>{translate('支付指南，')}</li>
                    <li>{translate('登录手机银行，')}</li>
                    <li>{translate('选择【支付】，')}</li>
                    <li>{translate('选择【多付款】，')}</li>
                    <li>{translate('【Transferpay】输入，')}</li>
                    <li>{translate('作为【服务提供商】，')}</li>
                    <li>
                      {translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}
                    </li>
                    <li>{translate('选择【继续】，')}</li>
                    <li>{translate('输入【OTP】和【PIN】，')}</li>
                    <li>{translate('选择【确定】，')}</li>
                    <li>{translate('显示付款证明，')}</li>
                    <li>{translate('完')}</li>
                  </ul>
                </div>
                <div {...className('bankTransfer-guide-content')}>
                  <p {...className('guide-note')}>
                    {translate(
                      '*请记录页面上的虚拟账户以及支付信息，方便操作转账，最好是能够截屏保存。',
                    )}
                  </p>
                  <p
                    {...className('guideline', {
                      expand: showPaymentGuide,
                    })}
                    onClick={() => togglePaymentGuide(showPaymentGuide)}
                  >
                    <span>{translate('查看更多')}>></span>
                  </p>

                  <ul
                    {...className('backTransfer-guide-list', {
                      hide: !showPaymentGuide,
                    })}
                  >
                    <li>{translate('支付指南，')}</li>
                    <li>{translate('选择【付费/购买】菜单，')}</li>
                    <li>{translate('选择【其他】，')}</li>
                    <li>{translate('选择【多付款】，')}</li>
                    <li>{translate('【12345】输入 - 例如，')}</li>
                    <li>{translate('正如【机构代码】，')}</li>
                    <li>
                      {translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}
                    </li>
                    <li>{translate('选择【右】，')}</li>
                    <li>{translate('选择【是】，')}</li>
                    <li>{translate('选择【是】，')}</li>
                    <li>{translate('拿你的付款证明，')}</li>
                    <li>{translate('完')}</li>
                  </ul>
                </div>
              </Tabs>
            </div>
          </div>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  showPaymentGuide: state.getIn([
    'paymentMethod',
    'storeOrBankTransferInfo',
    'showPaymentGuide',
  ]),
  isWhiteList: state.getIn(['main', 'isWhiteList']),
})

const mapDispatchToProps = (dispatch) => ({
  getPaymentResult({
    curPeriod,
    price,
    callbackPageUrl,
    payId,
    sign,
    nonce,
    bizIds,
  }) {
    BizTracker.click({ cn: 2 })
    dispatch(
      actionCreator.goGetPaymentResult({
        curPeriod,
        price,
        callbackPageUrl,
        payId,
        sign,
        nonce,
        bizIds,
      }),
    )
  },
  togglePaymentGuide(show) {
    dispatch(actionCreator.goTogglePaymentGuide(!show))
  },
  enableCopyPaymentCode(payCode) {
    const clipboard = new Clipboard('#copyPayCode')
    const translate = Translate.getInstance().translate

    clipboard.on('success', (e) => {
      BizTracker.click({ cn: 1 })
      dispatch(globalActionCreator.toggleToast(true, translate('复制成功')))
      e.clearSelection()
    })
  },
  reselectPaymentMethod() {
    BizTracker.click({ cn: 3 })
    dispatch(goBack())
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentCode),
)
