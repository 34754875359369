/*
 * 越南napas支付网关
 * gatewayInfo {Object} 网关信息
 */
export const callNapasGateway = (gatewayInfo) => {
  const body = document.body
  const napsScript = document.createElement('script')

  //创建一个空节点并追加到body上
  const napasNode = document.createElement('div')
  napasNode.id = 'napasGateway'
  body.appendChild(napasNode)

  //在节点内添加如下dom结构
  napasNode.innerHTML = `<form id="merchant-form" action="${gatewayInfo.url}" method="POST">
        <div id="napas-widget-container"></div>
        <script
            type="text/javascript"
            id="napas-widget-script"
            merchantId="${gatewayInfo.merchantId}"
            clientIP="${gatewayInfo.clientIP}"
            deviceId="${gatewayInfo.deviceId}"
            environment="${gatewayInfo.environment}"
            cardScheme="${gatewayInfo.cardScheme}"
            enable3DSecure="${gatewayInfo.enable3DSecure}"
            apiOperation="${gatewayInfo.apiOperation}"
            orderAmount="${gatewayInfo.orderAmount}"
            orderCurrency="${gatewayInfo.orderCurrency}"
            orderReference="${gatewayInfo.orderReference}"
            orderId="${gatewayInfo.orderId}"
            channel="${gatewayInfo.channel}"
            sourceOfFundsType="${gatewayInfo.sourceOfFundsType}"
            dataKey="${gatewayInfo.dataKey}"
            napasKey="${gatewayInfo.napasKey}"
        >
        </script>
    </form>`

  //请求napas资源
  //napas.paymentpage.min.js中会根据之前创建的DOM结构获取标签上的参数，从而进一步做逻辑处理和页面跳转
  napsScript.src = `https://dps-staging.napas.com.vn/api/restjs/resources/js/napas.paymentpage.min.js`
  body.appendChild(napsScript)
}

export const callCommonGateway = (gatewayInfo) => {
  const body = document.body

  //创建一个Form表单
  const form = document.createElement('form')
  form.id = 'form'
  form.action = gatewayInfo.url
  form.method = 'POST'

  let inputStr = ''
  Object.keys(gatewayInfo).forEach((key) => {
    if (key !== 'url')
      inputStr += `<input type="hidden" name="${key}" value="${gatewayInfo[key]}" />`
  })

  form.innerHTML = inputStr

  body.appendChild(form)

  form.submit()
}
