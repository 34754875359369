import React, { Fragment } from 'react'
import { helpers } from '@common'

import styles from './style.m.scss'
import { Translate } from '@pc/config/translate'
const className = helpers.classNames.react(styles)

export const CreditCard = (props) => {
  const { isVisa, cardNmuber, expireMonth, expireYear, cvvNumber, platform } =
    props
  const translate = Translate.getInstance().translate

  return (
    <div
      {...className('card-image', {
        'visa-card': isVisa,
        'master-card': !isVisa,
        'card-image-pc': platform === 'pc',
      })}
    >
      <div {...className('card-detail creditCard-number')}>
        <span {...className('detail-title')}>{translate('信用卡号')}</span>
        <p {...className('card-number-wrapper')}>
          {translateCardNumber(cardNmuber)}
        </p>
      </div>
      <div {...className('card-detail expireation-date')}>
        <span {...className('detail-title')}>{translate('到期时间')}</span>
        <span>
          {expireMonth} {expireYear}
        </span>
      </div>
      <div {...className('card-detail cvv-number')}>
        <span {...className('detail-title')}>CVV/CCV</span>
        <span>{cvvNumber}</span>
      </div>
    </div>
  )
}

const translateCardNumber = (cardNmuber) => {
  const len = cardNmuber.toString().length
  if (len < 16) {
    cardNmuber += '•'.repeat(16 - len)
  }

  const charArr = cardNmuber.split('').map((item, index) => {
    if (index === 3 || index === 7 || index === 11) {
      item += ' ' //添加一个空格
    }
    return item
  })

  return (
    <Fragment>
      <span>{`${charArr[0]}${charArr[1]}${charArr[2]}${charArr[3]}`}</span>
      <span>{`${charArr[4]}${charArr[5]}${charArr[6]}${charArr[7]}`}</span>
      <span>{`${charArr[8]}${charArr[9]}${charArr[10]}${charArr[11]}`}</span>
      <span>{`${charArr[12]}${charArr[13]}${charArr[14]}${charArr[15]}`}</span>
    </Fragment>
  )
  console.log(
    cardNmuber.split('').map((item, index) => {
      if (index === 3 || index === 7 || index === 11) {
        item += ' ' //添加一个空格
      }
      return item
    }),
  )
  return cardNmuber
    .split('')
    .map((item, index) => {
      if (index === 3 || index === 7 || index === 11) {
        item += ' ' //添加一个空格
      }
      return item
    })
    .join('')
}
