import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from './style.m.scss'
import { helpers } from '@common'

import Icon from '../Icon'

const _className = helpers.classNames.react(styles)

export class Selector extends PureComponent {
  constructor(props) {
    super(props)

    const defaultVal = props.value
    let defalutList = props.list.filter((item) => {
      return item.value === defaultVal
    })

    if (defalutList.length === 0) {
      defalutList = props.list
    }

    this.state = {
      __Icon: defalutList[0].icon,
      text: defalutList[0].text,
      value: defalutList[0].name,
      show: false,
    }

    this.onSelected = this.onSelected.bind(this)
    this.onExpand = this.onExpand.bind(this)
    this.onCollapse = this.onCollapse.bind(this)
  }

  onSelected(value, text, __Icon) {
    const { onSelect } = this.props
    this.onCollapse()
    this.setState({
      value,
      text,
      __Icon,
    })
    value !== this.state.value && onSelect(value)
  }

  onExpand() {
    const { disabled } = this.props

    if (disabled) return

    this.setState({
      show: true,
    })
  }

  onCollapse() {
    const { disabled } = this.props

    if (disabled) return

    this.setState({
      show: false,
    })
  }

  componentDidMount() {
    document.addEventListener('click', this.onCollapse, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onCollapse, true)
  }

  render() {
    const { list, className, hasArrow, platform } = this.props
    const { url, text, __Icon, show } = this.state

    return (
      <div
        {..._className('selector-container', {
          'selector-container-pc': platform === 'pc',
        })}
      >
        <div
          {..._className(`selected-item ${className}`)}
          onClick={() => this.onExpand()}
          expanded={show.toString()}
        >
          {__Icon}
          {text && <span {..._className('icon-text')}>{text}</span>}
          {hasArrow && (
            <Icon {..._className(`selector-arrow`)} icontype="svg" name="Up" />
          )}
        </div>
        <div
          {..._className('selector-body', {
            hide: !show,
          })}
        >
          {list.map((item, index) => {
            return (
              <div
                {..._className(`selector-item ${className}`)}
                name={item.value}
                onClick={() =>
                  this.onSelected(item.value, item.text, item.icon)
                }
                key={item.value}
              >
                {item.icon}
                <span {..._className('icon-text')}>{item.text}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

Selector.propTypes = {
  list: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}
