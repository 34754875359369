import React, { PureComponent } from 'react'
import { helpers } from '@common'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

export class DynamicClock extends PureComponent {
  constructor() {
    super()
    this.state = {
      time: ['00', '00', '00'],
    }

    this.startCountDown = this.startCountDown.bind(this)
  }

  render() {
    const { time } = this.state
    const { platform } = this.props

    return (
      <div {...className('dynamic-clock')}>
        <div
          {...className('dynamic-clock-area', {
            'dynamic-clock-area-pc': platform === 'pc',
          })}
        >
          <span {...className('dynamic-clock-block')}>{time[0]}</span>
          <span {...className('dynamic-clock-separator')}>:</span>
          <span {...className('dynamic-clock-block')}>{time[1]}</span>
          <span {...className('dynamic-clock-separator')}>:</span>
          <span {...className('dynamic-clock-block')}>{time[2]}</span>
        </div>
      </div>
    )
  }

  startCountDown(endTime) {
    //endTime 截止时间
    const nowTime = new Date().getTime()
    const leftTime = endTime - nowTime
    this.timer = setTimeout(this.startCountDown, 1000, endTime)

    if (leftTime >= 0) {
      let hour = Math.floor((leftTime / 1000 / 60 / 60) % 24),
        min = Math.floor((leftTime / 1000 / 60) % 60),
        sec = Math.floor((leftTime / 1000) % 60)

      if (sec < 10) {
        sec = `0${sec}`
      }
      if (min < 10) {
        min = `0${min}`
      }
      if (hour < 10) {
        hour = `0${hour}`
      }
      this.setState({
        time: [hour, min, sec],
      })
    } else {
      this.setState({
        time: ['00', '00', '00'],
      })
      clearTimeout(this.timer)
      return
    }
  }

  componentDidMount() {
    const { endTime, type } = this.props

    type.toLowerCase() === 'countdown'
      ? this.startCountDown(endTime)
      : this.startClock()
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }
}
