import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'

import { Selector } from '@pc/components/Selector'
// import { AdvanceInput } from '@pc/components/AdvanceInput'
import { AdvanceInput } from '../../../login/components/InputFields'
import { Button } from '@pc/components/Button'
import Icon from '@pc/components/Icon'

import creditTimeImg from '@pc/assets/img/icon_1.png'
import creditCVImg from '@pc/assets/img/icon_2.png'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class CreditCardForm extends PureComponent {
  constructor() {
    super()
    this.controls = []
  }

  //保存控件实例到数组中
  register = (instance) => {
    this.controls.push(instance)
  }

  //收集控件value值保存到表单state中
  collect = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  beforeSubmit = () => {
    let validateStatus

    validateStatus = this.controls.every((elem) => {
      //调用子组件自身的验证方法
      return !elem.validate(elem.state.value) //返回false代表验证成功
    })
    return validateStatus
  }

  submit = () => {
    const {
      payByCreditCard,
      history: {
        location: { state },
      },
      subId,
      payMethod,
    } = this.props
    const { id, type, amount, price } = state

    if (this.beforeSubmit()) {
      payByCreditCard({
        ...this.state,
        id,
        type,
        subId: subId ? subId : state.subId,
        payMethod: payMethod ? payMethod : state.payMethod,
        amount: amount ? amount : price,
      })
    }
  }

  render() {
    const {
      translate,
      formatPrice,
      changeCardNumber,
      changeCVVnumber,
      changeExpireMonth,
      changeExpireYear,
      changeCreditCard,
      setCardNumberErrText,
      cardNumberErrText,
      setCardCvvErrText,
      cardCvvErrText,
      setCardMmErrText,
      cardMmErrText,
      setCardYyErrText,
      cardYyErrText,
      disabled,
    } = this.props
    const { isVisa, isMaster, platform } = this.props

    return (
      <form
        {...className('creditCard-form', {
          'form-disable': disabled,
          'creditCard-form-pc': platform === 'pc',
        })}
      >
        {platform === 'pc' && <h2>{translate('卡信息')}</h2>}
        {platform === 'pc' && <h3>{translate('账号')}</h3>}
        <div {...className('creditCard-input')}>
          <AdvanceInput
            name="creditCardNumber"
            type="number"
            platform={platform}
            placeholder={translate('信用卡号')}
            onRegister={this.register}
            onControl={this.collect}
            validation={{ type: 'creditCard' }}
            className="border"
            maxLength="16"
            errText={cardNumberErrText}
            onChange={(num) => {
              changeCardNumber(num)
            }}
            onError={(errText) => {
              setCardNumberErrText(errText)
            }}
            onSuccess={(errText) => {
              setCardNumberErrText('')
            }}
            onFocus={(errText) => {
              setCardNumberErrText('')
            }}
            disabled={disabled}
          >
            <Selector
              hasArrow={true}
              list={[
                {
                  icon: (
                    <Icon
                      {...className('creditCard-icon-master')}
                      icontype="svg"
                      name="master"
                    />
                  ),
                  value: 'isMaster',
                },
                {
                  icon: (
                    <Icon
                      {...className('creditCard-icon-visa')}
                      icontype="svg"
                      name="visa"
                    />
                  ),
                  value: 'isVisa',
                },
              ]}
              onSelect={(name) => changeCreditCard(name)}
              className="credit-icon"
              disabled={disabled}
              platform={platform}
            />
          </AdvanceInput>
        </div>
        {platform === 'pc' && <h3>{translate('过期时间')}</h3>}
        <div {...className('creditTime-input-area')}>
          <AdvanceInput
            name="expireMonth"
            type="number"
            platform={platform}
            placeholder={translate('MM')}
            onRegister={this.register}
            onControl={this.collect}
            validation={{ type: 'number', range: [2] }}
            className="border"
            maxLength="2"
            errText={cardMmErrText}
            onChange={(month) => {
              changeExpireMonth(month)
            }}
            onError={(errText) => {
              setCardMmErrText(errText)
            }}
            onSuccess={(errText) => {
              setCardMmErrText('')
            }}
            onFocus={(errText) => {
              setCardMmErrText('')
            }}
            required
            hasTips
            disabled={disabled}
          >
            {platform !== 'pc' && (
              <Icon
                {...className('prefix-icon')}
                icontype="image"
                src={creditTimeImg}
              />
            )}
          </AdvanceInput>
          <span />
          <AdvanceInput
            name="expireYear"
            type="number"
            platform={platform}
            placeholder={translate('YY')}
            onRegister={this.register}
            onControl={this.collect}
            validation={{ type: 'number', range: [2] }}
            className="border"
            maxLength="2"
            errText={cardYyErrText}
            onChange={(year) => {
              changeExpireYear(year)
            }}
            onError={(errText) => {
              setCardYyErrText(errText)
            }}
            onSuccess={(errText) => {
              setCardYyErrText('')
            }}
            onFocus={(errText) => {
              setCardYyErrText('')
            }}
            required
            hasTips
            disabled={disabled}
          />
        </div>
        {platform === 'pc' && <h3>{translate('CVV')}</h3>}
        <div {...className('cvv-input')}>
          <AdvanceInput
            name="cardCVV"
            type="number"
            platform={platform}
            placeholder={translate('输入CVV号码的最后3-4位数字')}
            onRegister={this.register}
            onControl={this.collect}
            validation={{ type: 'number', range: [3, 4] }}
            maxLength="4"
            errText={cardCvvErrText}
            className="border"
            onChange={(cvv) => {
              changeCVVnumber(cvv)
            }}
            onError={(errText) => {
              setCardCvvErrText(errText)
            }}
            onSuccess={(errText) => {
              setCardCvvErrText('')
            }}
            onFocus={(errText) => {
              setCardCvvErrText('')
            }}
            required
            hasTips
            disabled={disabled}
          >
            {platform !== 'pc' && (
              <Icon
                {...className('prefix-icon')}
                icontype="image"
                src={creditCVImg}
              />
            )}
          </AdvanceInput>
        </div>
        {disabled ? null : (
          <Button
            platform={platform}
            text={translate('提交')}
            {...className('', {
              'visa-btn': isVisa,
              'master-btn': isMaster,
            })}
            onClick={this.submit}
          />
        )}
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  isMaster: state.getIn(['paymentMethod', 'isMaster']),
  isVisa: state.getIn(['paymentMethod', 'isVisa']),
  amount: state.getIn(['paymentMethod', 'methodInfo', 'amount']),
  cardNumberErrText: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'cardNumberErrText',
  ]),
  cardMmErrText: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'cardMmErrText',
  ]),
  cardYyErrText: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'cardYyErrText',
  ]),
  cardCvvErrText: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'cardCvvErrText',
  ]),
})

const mapDispatchToProps = (dispatch) => ({
  changeCardNumber(cardNumber) {
    dispatch(actionCreator.goChangeCardNumber(cardNumber))
  },
  changeExpireYear(year) {
    if (year === '') year = 'YY'

    dispatch(actionCreator.goChangeExpireYear(year))
  },
  changeExpireMonth(month) {
    if (month === '') month = 'MM'

    dispatch(actionCreator.goChangeExpireMonth(month))
  },
  changeCVVnumber(cvv) {
    if (cvv === '') cvv = 'CODE'

    dispatch(actionCreator.goChangeCVVnumber(cvv))
  },
  setCardNumberErrText(errText) {
    dispatch(actionCreator.goSetCardNumberErrText(errText))
  },
  setCardMmErrText(errText) {
    dispatch(actionCreator.goSetCardMmErrText(errText))
  },
  setCardYyErrText(errText) {
    dispatch(actionCreator.goSetCardYyErrText(errText))
  },
  setCardCvvErrText(errText) {
    dispatch(actionCreator.goSetCardCvvErrText(errText))
  },
  changeCreditCard(name) {
    let cardObj = {
      isVisa: false,
      isMaster: false,
    }
    Object.keys(cardObj).forEach((key) => {
      if (key === name) {
        cardObj[key] = true
      }
    })
    dispatch(actionCreator.goChangeCreditCard(cardObj))
  },
  payByCreditCard(cardInfo) {
    dispatch(actionCreator.goConnectWithVeritrans(cardInfo))
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreditCardForm),
)
