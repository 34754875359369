import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { globalActionCreator } from '@pc/common/globalActions'
import { Translate } from '@pc/config/translate'

import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import { Button } from '@pc/components/Button'
// import Logger, * as elementId from '@pc/config/logger'

import momoIcon from '@pc/assets/img/momo.png'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class PaymentMoMo extends PureComponent {
  render() {
    const { translate, currencyCode, formatPrice, checkMoMoResult } = this.props
    const {
      location: {
        state: { curPeriod, price, callbackPageUrl, payId },
      },
    } = this.props

    return (
      <section {...className('momo-wrapper')}>
        <div {...className('momo-container')}>
          <div {...className('momo-icon')}>
            <img src={momoIcon} alt="" />
          </div>

          <div {...className('momo-amount')}>
            <p>{translate('您需要支付')}</p>
            <p {...className('font-din-bold')}>
              <span>{currencyCode} </span>
              <span>{formatPrice(price)}</span>
            </p>
          </div>

          <div {...className('momo-btn')}>
            <Button
              text={translate('MoMo支付')}
              {...className('btn-momo')}
              onClick={this.callMoMoPayment}
            />
            <Button
              text={translate('查看支付结果')}
              hollow={true}
              {...className('btn-momo-hollow')}
              onClick={() =>
                checkMoMoResult(curPeriod, price, callbackPageUrl, payId)
              }
            />
            <a onClick={this.backToMerchant}>{translate('返回商家')}</a>
          </div>
        </div>
      </section>
    )
  }

  callMoMoPayment = () => {
    const {
      location: {
        state: { link },
      },
      awakeMoMoPaymet,
    } = this.props

    awakeMoMoPaymet(link)
  }

  backToMerchant = () => {
    const {
      appId,
      refNo,
      sign,
      location: {
        state: { callbackPageUrl },
      },
    } = this.props

    window.location.href = `
    ${decodeURIComponent(
      callbackPageUrl,
    )}?appId=${appId}&refNo=${refNo}&sign=${sign}
    `
  }
}

const mapStateToProps = (state) => ({
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
})

const mapDispatchToProps = (dispatch) => ({
  checkMoMoResult(curPeriod, price, callbackPageUrl, payId) {
    dispatch(
      actionCreator.goGetPaymentResult(
        curPeriod,
        price,
        callbackPageUrl,
        payId,
      ),
    )
  },
  awakeMoMoPaymet(deeplink) {
    const openTime = +new Date()
    const translate = Translate.getInstance().translate

    location.href = deeplink

    dispatch(globalActionCreator.toggleLoading(true))
    const timer = setTimeout(() => {
      dispatch(globalActionCreator.toggleLoading(false))
      if (new Date() - openTime < 3200) {
        //加了200ms基准误差

        dispatch(
          globalActionCreator.toggleAlertDialog(true, {
            title: '',
            content: translate('请先下载MoMoApp再重试完成支付哟'),
            footer: translate('我知道了'),
          }),
        )
      }
      if (new Date() - openTime > 3200) {
        clearTimeout(timer)
      }
    }, 3000)
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentMoMo),
)
