import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import { Button } from '@pc/components/Button'
import CreditCardForm from '../../components/CreditCardForm'
import { CreditCard } from '../../components/CreditCard'
import { MidtransIframePopup } from '../../components/MidtransIframePopup'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class PaymentCreditCardPc extends PureComponent {
  render() {
    const { translate, formatPrice } = this.props
    const {
      isVisa,
      isMaster,
      cardNmuber,
      expireMonth,
      expireYear,
      cvvNumber,
      midtransUrl,
      hasSubmitted,
      closeMidtransIframe,
      checkCreditPaymentResult,
      history: {
        location: {
          state: { id, callbackPageUrl, curPeriod, amount },
        },
      },
    } = this.props

    return (
      <section>
        <div {...className('creditCard-area')}>
          <MidtransIframePopup
            midtransUrl={midtransUrl}
            closeIframe={closeMidtransIframe}
          />
          <CreditCard
            isVisa={isVisa}
            cardNmuber={cardNmuber}
            expireMonth={expireMonth}
            expireYear={expireYear}
            cvvNumber={cvvNumber}
          />
        </div>
        <div {...className('payment-amount')}>
          <p>{translate('商品总额（Rp）')}</p>
          <p {...className('font-lato-bol')}>{formatPrice(amount)}</p>
        </div>
        <CreditCardForm disabled={hasSubmitted} />
        <div {...className('creditCard-area-footer')}>
          {!hasSubmitted ? null : (
            <Button
              text={translate('查询支付结果')}
              {...className('', {
                'visa-btn': isVisa,
                'master-btn': isMaster,
              })}
              onClick={() =>
                checkCreditPaymentResult(curPeriod, amount, callbackPageUrl, id)
              }
            />
          )}
        </div>
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  cardNmuber: state.getIn(['paymentMethod', 'creditCardInfo', 'cardNumber']),
  expireMonth: state.getIn(['paymentMethod', 'creditCardInfo', 'expireMonth']),
  expireYear: state.getIn(['paymentMethod', 'creditCardInfo', 'expireYear']),
  cvvNumber: state.getIn(['paymentMethod', 'creditCardInfo', 'cvvNumber']),
  hasSubmitted: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'hasSubmitted',
  ]),
  isMaster: state.getIn(['paymentMethod', 'isMaster']),
  isVisa: state.getIn(['paymentMethod', 'isVisa']),
  midtransUrl: state.getIn(['paymentMethod', 'midtransUrl']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  closeMidtransIframe() {
    dispatch(actionCreator.removeMidtransUrl())
  },
  checkCreditPaymentResult(curPeriod, price, callbackPageUrl, payId) {
    dispatch(
      actionCreator.goGetPaymentResult(
        curPeriod,
        price,
        callbackPageUrl,
        payId,
      ),
    )
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentCreditCardPc),
)
