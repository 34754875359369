import { actions } from './index'
import { helpers } from '@common'
import axios from 'axios'
import md5 from 'md5'
import { store } from '../../../store'
import { push } from 'connected-react-router'
import { globalActionCreator } from '../../../common/globalActions'
import { callNapasGateway, callCommonGateway } from '../paymentGateway'
import { Translate } from '../../../config/translate'
import paymentMethod from '..'
import Logger from '@pc/config/logger'
import loggerConfig from '../logger.config'

const { get } = helpers

//新接口改了属性名，这里做下转换，保证组件内取值写法不变
function transformMethodData(originData) {
  originData['amount'] = originData['totalAmount']
  originData['list'] = originData['groups']

  originData['list'].forEach((item) => {
    item['list'] = item['methods']

    item['list'].forEach((payGateway) => {
      payGateway['payMethod'] = payGateway['methodId']
      payGateway['subId'] = payGateway['subMethodId']
      payGateway['popupType'] = payGateway['type']
    })
  })

  return originData
}

function makeASCIIorderString(obj) {
  let arr = Object.keys(obj).sort((a, b) => {
    return a.charCodeAt('0') - b.charCodeAt('0')
  })
  let str = ''
  arr.forEach((key) => {
    str += `${key}=${obj[key]}&`
  })
  return str.slice(0, str.length - 1)
}

function makeSign(srcData, key) {
  let strTemp = `${makeASCIIorderString(srcData)}&key=${key}`
  let sign = md5(strTemp).toUpperCase()
  return sign
}

const CREDIT_CLIENT = 2 //信用卡支付
const THIRD_PARTY_GATEWAY = 1 //第三方支付网管
const PAYMENT_CODE = 3 //支付码
const BANCK_TRANSFER = 4 //银行转账
const CONVENIENCE_STORE = 36 // 便利店支付 如：Alfamart

/*
 * 拉取支付方式——新
 */
const __fetchMethodListNew = (params) => {
  const { bizIds, countryId, language = 'in', payId } = params

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const { data } = await axios.post(
      '/capi/payment/checkout/pay-methods',
      { payId, language },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    dispatch({
      type: actions.ADD_METHOD_INFO,
      methodInfo: transformMethodData(data.data),
      // __id: payId
      // __type: type
    })
  }
}

/*
 * 拉取支付方式列表
 */
export const goGetMethodsList = (data) => {
  const state = store.getState()
  const countryId = state.getIn(['main', 'countryId'])
  const language = state.getIn(['main', 'languageCode'])
  // const isWhiteList = state.getIn(['main', 'isWhiteList'])

  return async (dispatch) => {
    dispatch(
      __fetchMethodListNew({
        countryId,
        language,
        bizIds: data.bizIds[0],
        payId: data.payId,
      }),
    )
  }
}

/*
 * 调用支付网关逻辑
 * {cardInfo} Objec
 * @see https://api-docs.midtrans.com/#get-token
 */
export const goConnectWithVeritrans = (cardInfo) => {
  const { Veritrans } = window

  Veritrans.url =
    process.env.NODE_ENV === 'development' ||
    window.location.href.includes('test')
      ? 'https://api.sandbox.midtrans.com/v2/token'
      : 'https://api.midtrans.com/v2/token'
  Veritrans.client_key =
    process.env.NODE_ENV === 'development' ||
    window.location.href.includes('test')
      ? 'VT-client-kwwKEZzCxz6h7AGb'
      : 'VT-client-mZDJkNV5wsea1eSC' //正式环境的clientKey
  const card = () => ({
    card_number: cardInfo.creditCardNumber,
    card_cvv: cardInfo.cardCVV,
    card_exp_month: cardInfo.expireMonth,
    card_exp_year: cardInfo.expireYear,
    secure: true,
    gross_amount: cardInfo.amount,
  })

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))

    try {
      Veritrans.token(card, (res) => {
        dispatch(globalActionCreator.toggleLoading(false))

        console.log(res.redirect_url)
        if (res.redirect_url) {
          dispatch(__addMidtransUrl(res.redirect_url))
        } else if (res.status_code === '200') {
          const paymentData = {
            id: cardInfo.id,
            type: cardInfo.type,
            payMethod: cardInfo.payMethod,
            subId: cardInfo.subId,
            // cardNumber: cardInfo.creditCardNumber,
            // cardCvv: cardInfo.cardCVV,
            // cardExpMonth: cardInfo.expireMonth,
            // cardExpYear: cardInfo.expireYear,
            token: res.token_id,
          }

          dispatch(goPayByCreditCard(paymentData))
        } else {
          const translate = Translate.getInstance().translate

          //清除网关url
          dispatch(__addMidtransUrl(''))

          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: res.status_message,
              footer: translate('我知道了'),
            }),
          )
        }
      })
    } catch (e) {
      dispatch(globalActionCreator.toggleLoading(false))
    }
  }
}

/*
 * 提交信用卡信息
 * paymentData {Object} 信用卡信息
 */
const goPayByCreditCard = (paymentData) => {
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { success, errCode, sysMsg },
    } = await axios.post('/installment/api/json/pay.do', {
      ...paymentData,
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      dispatch(__changeCreditSubmitStatus(true))
      dispatch(removeMidtransUrl())
    } else {
      switch (errCode) {
        case 'PAY.0001':
          //订单已经支付，您只需等待，无需再付款
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('订单已经支付，您只需等待，无需再付款'),
            ),
          )
          break

        case 'PAY.0002':
          //超时
          dispatch(globalActionCreator.toggleToast(true, translate('支付超时')))
          break

        case 'PAY.0003':
          //超时
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('生成code或者支付中'),
            ),
          )
          break

        case 'SYSTEM.0006':
          //Denied by Bank Transaction
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('对不起，您的支付被银行拒绝'),
            ),
          )
          break

        default:
          dispatch(
            globalActionCreator.toggleAlertDialog(true, {
              title: translate('提示'),
              content: sysMsg ? sysMsg : errCode,
              footer: translate('我知道了'),
            }),
          )
          break
      }
    }
  }
}

/*
 * 修改信用卡信息提交状态
 * status {boolean}
 */
const __changeCreditSubmitStatus = (status) => ({
  type: actions.CHANGE_CREDIT_SUBMIT_STATUS,
  status,
})

/*
 * 设置卡错误提示
 */
export const goSetCardNumberErrText = (errText) => ({
  type: actions.SET_CARD_NUMBER_ERROR_TEXT,
  errText,
})

/*
 * 设置卡mm错误提示
 */
export const goSetCardMmErrText = (errText) => ({
  type: actions.SET_CARD_MM_ERROR_TEXT,
  errText,
})

/*
 * 设置卡yy错误提示
 */
export const goSetCardYyErrText = (errText) => ({
  type: actions.SET_CARD_YY_ERROR_TEXT,
  errText,
})

/*
 * 设置卡cvv错误提示
 */
export const goSetCardCvvErrText = (errText) => ({
  type: actions.SET_CARD_CVV_ERROR_TEXT,
  errText,
})

/*
 * 修改当前信用卡
 * cardObj {Object} 当前信用卡标志
 */
export const goChangeCreditCard = (cardObj) => ({
  type: actions.CHANGE_CREDIT_CARD,
  cardObj,
})

/*
 * 修改信用卡号
 * cardNumber {string} 信用卡号
 */
export const goChangeCardNumber = (cardNumber) => ({
  type: actions.CHANGE_CARD_NUMBER,
  cardNumber,
})

/*
 * 修改到期年份
 * expireYear {string} 到期年份
 */
export const goChangeExpireYear = (expireYear) => ({
  type: actions.CHANGE_EXPIRE_YEAR,
  expireYear,
})

/*
 * 修改到期月份
 * expireYear {string} 到期月份
 */
export const goChangeExpireMonth = (expireMonth) => ({
  type: actions.CHANGE_EXPIRE_MONTH,
  expireMonth,
})

/*
 * 修改到期时间
 */
export const goChangeCVVnumber = (cvvNumber) => ({
  type: actions.CHANGE_CVV_NUMBER,
  cvvNumber,
})

/*
 * 记录当前选择的支付方式
 * curMethod {immutable} 当前选择的支付方式
 */
export const goRecordCurrentMethod = (curMethod) => ({
  type: actions.RECORD_CURRENT_METHOD,
  curMethod,
})

/*
 * 添加midtrans返回的支付网关url
 * midtransUrl {string}
 */
const __addMidtransUrl = (midtransUrl) => ({
  type: actions.ADD_MIDTRANS_URL,
  midtransUrl,
})

/*
 * 清除midtrans返回的支付网关url
 */
export const removeMidtransUrl = () => ({
  type: actions.REMOVE_MIDTRANS_URL,
  midtransUrl: '',
})

/*
 * 添加后台返回的便利店信息或者银行转账信息
 * storeOrBankInfo {Object} 后台返回该支付方式的关键信息，例如转账账号、付款码等
 */
const __addStoreOrBankTransferInfo = (storeOrBankInfo) => ({
  type: actions.ADD_STORE_BANCKTRANSFER_INFO,
  storeOrBankInfo,
})

/*
 * 调用改接口获取
 * 1. 便利店支付支付码
 * 2. 银行卡转账信息
 *
 */
export const doPaymentCheckout = (params) => {
  const {
    bizIds,
    amount,
    methodId,
    subMethodId,
    curPeriod,
    callbackPageUrl,
    merchantImg,
    validateKeys,
    name,
    payId,
  } = params
  const nonce = Math.random().toFixed(16).replace('0.', '')
  const state = store.getState()
  const secretKey = state.getIn(['paymentMethod', 'methodInfo', 'secretKey'])
  const pathName = state.getIn(['router', 'location', 'pathname'])
  const sign = makeSign({ amount, methodId, subMethodId, nonce }, secretKey)

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errCode, errMsg },
    } = await axios.post(
      '/capi/payment/checkout/pay',
      {
        payId,
        methodId,
        subMethodId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )

    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.click_payment_method,
      Aku_buttonName: name,
      isNewUser: 1,
      code: errCode,
      Aku_msg: errMsg,
    })

    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      if (
        data.type === BANCK_TRANSFER ||
        data.type === PAYMENT_CODE ||
        data.type === CONVENIENCE_STORE
      ) {
        dispatch(
          push({
            pathname: `${pathName}/paymentCode`,
            state: {
              payCode:
                data.type === BANCK_TRANSFER
                  ? data.bankInfo.bankAccountNo
                  : data.payCode.code,
              expire: data.expiredTime,
              curPeriod,
              amount,
              callbackPageUrl,
              merchantImg,
              targetName:
                data.type === BANCK_TRANSFER
                  ? data.bankInfo.bankName
                  : data.payCode.code,
              popupType: data.type === PAYMENT_CODE ? 'code' : 'va',
              nonce, //新流程需要的
              sign, //新流程需要的
              payId, //新流程需要的
              bizIds, //新流程需要的
              name, // 埋点需要
            },
            search: window.location.search,
          }),
        )
      } else if (data.type === THIRD_PARTY_GATEWAY) {
        //跳转到第三方支付
        if (data.payFrom.redirect) {
          window.location.href = data.payFrom.url
        } else {
          callCommonGateway(data.payFrom.formData)
        }
      } else {
        dispatch(
          push({
            pathname: `${pathName}/creditCard`,
            state: {
              // id: payId,
              callbackPageUrl,
              curPeriod,
              amount,
            },
            search: window.location.search,
          }),
        )
      }
    } else {
      dispatch(globalActionCreator.toggleToast(true, errMsg))
    }
  }
}

/*
 * 获取第三方支付网关信息
 * methodInfo {immutable} 当前选择的支付方式
 * curPeriod {string} 当前选择的分期数
 * amount {string} 当前订单价格
 * id {string} 支付记录的id，点击下单的时候生成的
 */
export const goGetPaymentGatewayInfo = (
  methodInfo,
  { curPeriod, amount, callbackPageUrl, id },
) => {
  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errCode },
    } = await axios.post('/installment/api/json/pay.do', {
      ...methodInfo,
    })
    dispatch(globalActionCreator.toggleLoading(false))
    __storeOrderDetailInSession(curPeriod, callbackPageUrl, amount, id)

    //跳转到momo支付
    if (data.deeplink) {
      const pathName = store
        .getState()
        .getIn(['router', 'location', 'pathname'])
      dispatch(
        push({
          pathname: `${pathName}/momo`,
          state: {
            link: data.deeplink,
            price: amount,
            curPeriod,
            callbackPageUrl,
            payId: id,
          },
          search: window.location.search,
        }),
      )
      return
    }

    if (data.redirect && !data.deeplink === false) {
      window.location.href = data.url
    } else {
      callCommonGateway(data)
    }
  }
}

//要跳转到第三方支付，需要利用session字段跳转到正确的结果页。具体细节看v1上的PayResult.html
const __storeOrderDetailInSession = (
  curPeriod,
  callbackPageUrl,
  amount,
  id,
) => {
  const state = store.getState()
  const queryString = state.getIn(['main', 'queryString'])

  sessionStorage.setItem('fromNewOpenpay', 'true')
  //结果页需要用到curPeriod, callbackPageUrl, amount
  sessionStorage.setItem(
    'newOpenpayQuery',
    `${queryString}&curPeriod=${curPeriod}&callbackPageUrl=${callbackPageUrl}&price=${amount}&id=${id}`,
  )
}

/*
 * 获取付款码
 * methodInfo {immutable} 当前选择的支付方式
 * curPeriod {string} 当前选择的分期数
 * amount {string} 当前订单价格
 */
export const goGetPaymentCode = (
  methodInfo,
  {
    curPeriod,
    amount,
    callbackPageUrl,
    targetName,
    merchantImg,
    popupType,
    payId,
  },
) => {
  const state = store.getState()
  const pathName = state.getIn(['router', 'location', 'pathname'])
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errCode, errMsg },
    } = await axios.post('/installment/api/json/pay.do', {
      ...methodInfo,
    })
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      dispatch(__addStoreOrBankTransferInfo(data))
      dispatch(
        push({
          pathname: `${pathName}/paymentCode`,
          state: {
            payCode: data.code,
            expire: data.expire,
            payId,
            curPeriod,
            amount,
            callbackPageUrl,
            merchantImg,
            targetName,
            popupType,
          },
          search: window.location.search,
        }),
      )
    } else {
      switch (errCode) {
        case 'PAY.0001':
          //订单已经支付，您只需等待，无需再付款
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('订单已经支付，您只需等待，无需再付款'),
            ),
          )
          break

        case 'PAY.0002':
          //超时
          dispatch(globalActionCreator.toggleToast(true, translate('支付超时')))
          break

        case 'PAY.0003':
          //超时
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('生成code或者支付中'),
            ),
          )
          break

        default:
          dispatch(
            globalActionCreator.toggleToast(true, errMsg ? errMsg : errCode),
          )
          break
      }
    }
  }
}

/*
 * 查询支付结果——老
 */
const __getPaymentResultNew = (params, translate) => {
  const {
    payId,
    sign,
    nonce,
    curPeriod,
    price,
    callbackPageUrl,
    isPc,
    bizIds,
  } = params
  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errMsg, errCode },
    } = await axios.post(
      '/capi/payment/checkout/pay-result',
      {
        payId,
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    dispatch(globalActionCreator.toggleLoading(false))

    if (success) {
      const { status } = data

      // status	状态1：已创建 2：系统认为可信3：风控认为可信 4：获取过验证码 7：支付进行中  8：支付失败 84: 支付关单操作 90：失败 91：用户退款 92：用户取消  94：返服务费（没上线） 100：成功 101：第三方已经收货
      switch (status) {
        //支付中
        case 1:
        case 7:
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('该笔订单还未支付成功！'),
            ),
          )
          break

        //支付被取消
        case 92:
          dispatch(
            globalActionCreator.toggleToast(true, translate('已取消支付')),
          )
          break

        //支付失败
        case 90:
        case 8:
          dispatch(globalActionCreator.toggleToast(true, translate('支付失败')))
          break

        //支付成功
        case 100:
          dispatch(
            push({
              pathname: isPc ? '/pc/paymentResult' : '/paymentResult',
              state: { curPeriod, price, callbackPageUrl },
              search: `${window.location.search}&status=success`,
            }),
          )
          break
        default:
          break
      }
    } else {
      if (errCode === '10811510010403') {
        dispatch(
          push({
            pathname: isPc ? '/pc/paymentResult' : '/paymentResult',
            state: { curPeriod, price, callbackPageUrl },
            search: `${window.location.search}&status=fail`,
          }),
        )
      }
      console.log('失败')
    }
  }
}

/*
 * 查询支付结果
 */
export const goGetPaymentResult = (params) => {
  const {
    curPeriod,
    price,
    callbackPageUrl,
    payId,
    isPc,
    nonce,
    sign,
    bizIds,
  } = params
  const state = store.getState(),
    appId = state.getIn(['main', 'appId'])
  // isWhiteList = state.getIn(['main', 'isWhiteList'])
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    dispatch(
      __getPaymentResultNew(
        {
          curPeriod,
          price,
          callbackPageUrl,
          nonce,
          sign,
          payId,
          bizIds,
          isPc,
        },
        translate,
      ),
    )
  }
}

/*
 * 显示/隐藏付款指引
 */
export const goTogglePaymentGuide = (show) => ({
  type: actions.TOGGLE_PAYMENT_GUIDE,
  show,
})

/*
 * 提交越南MoMo支付
 */
export const goMoMoPayment = (methodInfo) => {
  const pathName = store.getState().getIn(['router', 'location', 'pathname'])
  const translate = Translate.getInstance().translate

  return async (dispatch) => {
    dispatch(globalActionCreator.toggleLoading(true))
    const {
      data: { data, success, errMsg, errCode },
    } = await axios.post('/installment/api/json/pay.do', {
      ...methodInfo,
    })
    dispatch(globalActionCreator.toggleLoading(false))

    let callbackPageUrl = ''
    if (success) {
      dispatch(
        push({
          pathname: `${pathName}/momo`,
          state: { callbackPageUrl, link: data.deeplink },
          search: window.location.search,
        }),
      )
    } else {
      switch (errCode) {
        case 'PAY.0001':
          //订单已经支付，您只需等待，无需再付款
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('订单已经支付，您只需等待，无需再付款'),
            ),
          )
          break

        case 'PAY.0002':
          //超时
          dispatch(globalActionCreator.toggleToast(true, translate('支付超时')))
          break

        case 'PAY.0003':
          //超时
          dispatch(
            globalActionCreator.toggleToast(
              true,
              translate('生成code或者支付中'),
            ),
          )
          break

        default:
          dispatch(
            globalActionCreator.toggleToast(true, errMsg ? errMsg : errCode),
          )
          break
      }
    }
  }
}

/*
 * 选择支付方式
 * methodItem {Immutable} 当前所选的支付方式，包含一些关键信息
 * curPeriod {String} 所选的分期数
 * amount {String} 订单总价
 * callbackPageUrl
 * id
 * type
 */
export const goChoosePaymentMethod = ({
  methodItem,
  curPeriod,
  amount,
  callbackPageUrl,
  id,
  type,
}) => {
  const state = store.getState()
  const pathName = state.getIn(['router', 'location', 'pathname'])
  const popupType = methodItem.get('popupType')
  const subId = methodItem.get('subId')
  const payMethod = methodItem.get('payMethod')
  const targetName = methodItem.get('name')
  const merchantImg = methodItem.get('image')

  return (dispatch) => {
    //存储当前选择的支付方式
    dispatch(goRecordCurrentMethod(methodItem))

    //信用卡支付
    if (popupType === 'creditClient') {
      dispatch(
        push({
          pathname: `${pathName}/creditCard`,
          state: {
            id,
            type,
            subId,
            payMethod,
            callbackPageUrl,
            curPeriod,
            amount,
          },
          // state: { id, callbackPageUrl, curPeriod, amount },
          search: window.location.search,
        }),
      )
    } else {
      const postData = {
        id,
        type,
        payMethod: methodItem.get('payMethod'),
        subId: methodItem.get('subId'),
      }

      if (!popupType) {
        //准备跳转第三方支付网关（菲律宾/越南）
        dispatch(
          goGetPaymentGatewayInfo(postData, {
            curPeriod,
            amount,
            callbackPageUrl,
            id,
          }),
        )
      } else {
        //银行卡转账或者便利店
        dispatch(
          goGetPaymentCode(postData, {
            curPeriod,
            amount,
            callbackPageUrl,
            targetName,
            merchantImg,
            popupType,
            payId: id,
          }),
        )
      }

      // dispatch(push(`${pathName}/paymentCode`))
    }
  }
}
