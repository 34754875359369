import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Accordion, List } from 'antd-mobile'
import { toJS } from 'immutable'
import { push } from 'connected-react-router'
import { store } from '@pc/store'

import Icon from '@pc/components/Icon'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'

import styles from './style.m.scss'
import bill_img from '@pc/assets/img/bill_img.png'
const className = helpers.classNames.react(styles)

import Logger from '@pc/config/logger'
import loggerConfig from '../../logger.config'
import BizTracker from '@pc/common/tracker/bizTracker'
import { PAY_METHOD_STATUS } from '@pc/common/macro'

@withBasic
@helpers.hot(module)
class MethodList extends PureComponent {
  componentDidMount() {
    const saLogger = Logger.getInstance()
    saLogger.onPageView(loggerConfig.page_view)
  }

  render() {
    const { translate, formatPrice } = this.props
    const { methodInfo, currencyCode, match } = this.props
    const list = methodInfo.get('list'),
      listLen = list ? list.size : 0

    return (
      <section {...className('method-list-section')}>
        <div {...className('top-background')}>
          <div {...className('top-background-amount font-din-bold')}>
            <p>{translate('总金额')}</p>
            <p>
              {currencyCode}{' '}
              {listLen < 1 ? null : (
                <span {...className('fadeIn')}>
                  {formatPrice(methodInfo.get('amount'))}
                </span>
              )}
            </p>
          </div>
          <Icon icontype="image" src={bill_img} />
        </div>
        <div {...className('blank')} />
        {listLen > 0 ? (
          <Accordion
            defaultActiveKey={listLen === 1 ? '0' : null}
            {...className('my-accordion methodList')}
            onChange={(arr) => {
              const selectIndex = arr[arr.length - 1]
              if (selectIndex) {
                BizTracker.click({
                  cn: 1,
                  ext: {
                    first_channel_nm: list.get(selectIndex).get('groupName'),
                  },
                })
              }
            }}
          >
            {this.makeMethodList()}
          </Accordion>
        ) : null}
      </section>
    )
  }

  makeMethodList() {
    const { methodInfo, translate } = this.props
    const methodList = methodInfo.get('list')

    return methodList.map((item, index) => {
      return [
        <Accordion.Panel
          key={index}
          header={
            item.get('groupName') === 'default'
              ? translate('请选择支付方式')
              : item.get('groupName')
          }
          {...className('methodList-header  fadeIn')}
        >
          <List className="my-list">
            {this.makeMethodListItem(item.get('list'))}
          </List>
        </Accordion.Panel>,
        <div key={item.get('groupName')} {...className('blank')} />,
      ]
    })
  }

  makeMethodListItem(list) {
    const {
      choosePaymentMethod,
      choosePaymentMethodNew,
      methodInfo,
      countryCode,
      isWhiteList,
    } = this.props
    const {
      location: {
        state: { curPeriod, price, callbackPageUrl, id, type, payId, bizIds },
      },
    } = this.props

    return list.map((item, index) => {
      //越南/菲律宾隐藏货到付款
      if (countryCode === 'VN' && item.get('payMethod') === 40000) return null
      if (countryCode === 'PH' && item.get('payMethod') === 30000) return null

      // 停用状态不显示支付方式
      if (item.get('status') === PAY_METHOD_STATUS.DISABLED) return null

      return (
        <List.Item
          {...className('list-item', {
            // 异常情况下 需要置灰 不可点击
            abnormal: item.get('status') === PAY_METHOD_STATUS.ABNORMAL,
          })}
          key={index}
          onClick={() =>
            choosePaymentMethodNew({
              payId,
              bizIds,
              amount: methodInfo.get('amount'),
              methodId: item.get('payMethod'),
              subMethodId: item.get('subId'),
              merchantImg: item.get('image'),
              validateKeys: item.get('validateKeys'),
              callbackPageUrl,
              curPeriod,
              name: item.get('name'),
            })
          }
        >
          <img {...className('list-img')} src={item.get('image')} alt="" />
          <span {...className('list-item-name')}>{item.get('name')}</span>
          <i {...className('list-item-arrow')} />
        </List.Item>
      )
    })
  }
}

const preloadImage = (list) => {
  list.map((item, index) => {
    if (item.get('list')) {
      preloadImage(item.get('list'))
    } else if (item.get('image')) {
      const img = new Image()
      img.src = item.get('image')
    }
  })
}

const mapStateToProps = (state) => ({
  methodInfo: state.getIn(['paymentMethod', 'methodInfo']),
  isWhiteList: state.getIn(['main', 'isWhiteList']),
})

const mapDispatchToProps = (dispatch) => ({
  getMethodInfo(id, type) {
    dispatch(actionCreator.goGetMethodsList(id, type))
  },
  choosePaymentMethod({ item, curPeriod, amount, callbackPageUrl, id, type }) {
    dispatch(actionCreator.goRecordCurrentMethod(item))
    dispatch(
      actionCreator.goChoosePaymentMethod({
        methodItem: item,
        curPeriod,
        amount,
        callbackPageUrl,
        id,
        type,
      }),
    )
  },
  choosePaymentMethodNew({
    payId,
    bizIds,
    amount,
    methodId,
    subMethodId,
    merchantImg,
    validateKeys,
    callbackPageUrl,
    curPeriod,
    name,
  }) {
    const saLogger = Logger.getInstance()
    saLogger.onPageClick({
      ...loggerConfig.click_payment_method,
      Aku_buttonName: name,
      isNewUser: 1,
    })
    validateKeys = validateKeys
      ? validateKeys
          .filter((item) => {
            if (item.get('required')) {
              return item.get('key')
            }
          })
          .map((item) => item.get('key'))
          .toJS()
      : null
    dispatch(
      actionCreator.doPaymentCheckout({
        payId,
        bizIds,
        amount,
        methodId,
        subMethodId,
        merchantImg,
        validateKeys,
        callbackPageUrl,
        curPeriod,
        name,
      }),
    )
    BizTracker.click({
      cn: 2,
      ext: {
        first_channel_nm: methodId,
        second_channel_nm: name,
      },
    })
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MethodList),
)
