export default {
  // 收银台页面
  page_view: {
    Aku_page_id: 'openpay05',
    Aku_page_name: 'openpay checkout page',
  },
  // 收银台支付方式选择点击
  click_payment_method: {
    Aku_page_id: 'openpay05',
    Aku_page_name: 'openpay checkout page',
    Aku_element_id: 'openpay050101',
    Aku_element_name: 'click payment method',
    Aku_moudle_id: '01',
    Aku_moudle_name: 'checkout',
    Aku_position_id: '01',
    isNewUser: 1,
  },
  // 支付码页面
  page_view_payment_code: {
    Aku_page_id: 'openpay06',
  },
}
