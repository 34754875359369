import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter, Switch, Route } from 'react-router-dom'

import { actionCreator } from './store'
import { withBasic } from '@common'
import { ScrollView } from '@Arui'
import { transitionRoute } from '../../components/TransitionRoute'
import BizTracker from '@pc/common/tracker/bizTracker'
import MethodList from './components/MethodList'
import PaymentCode from './views/PaymentCode'
import PaymentCreditCard from './views/PaymentCreditCard'
import PaymentMoMo from './views/PaymentMoMo'
import withPerfCollect from '@/components/withPerfCollect'

import Logger from '@pc/config/logger'
import loggerConfig from './logger.config'
import { globalActionCreator } from '../../common/globalActions'
@transitionRoute
class Method extends PureComponent {
  render() {
    const { match } = this.props
    const { method } = match.params

    if (method === 'creditCard') {
      return <PaymentCreditCard />
    }

    if (method === 'paymentCode') {
      return <PaymentCode />
    }

    if (method === 'momo') {
      return <PaymentMoMo />
    }
    return <div />
  }
}

@withBasic
@transitionRoute
class PaymentMethod extends PureComponent {
  render() {
    const { match } = this.props

    return (
      <ScrollView>
        <Switch>
          <Route exact path={match.url} render={() => <MethodList />} />
          <Route path={`${match.url}/:method`} component={Method} />
        </Switch>
      </ScrollView>
    )
  }

  UNSAFE_componentWillMount() {
    loadMidtrans()
  }

  componentDidMount() {
    const { getMethodInfo, location } = this.props
    BizTracker.enter({
      sn: 390005,
    })
    getMethodInfo(location.state)
    this.getAndReportConfig()
  }

  getAndReportConfig = async () => {
    const saLogger = Logger.getInstance()
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    saLogger.onPageView({
      ...loggerConfig.page_view,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      appName: data?.appName,
    })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }
}

const mapStateToProps = (state) => ({
  methodInfo: state.getIn(['paymentMethod', 'methodInfo']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMethodInfo(locationState) {
    if (!locationState) return

    const { id, type, bizIds, payId } = locationState

    if ((id && type) || bizIds) {
      dispatch(
        actionCreator.goGetMethodsList(
          bizIds ? { bizIds, payId } : { id, type },
        ),
      )
    }
  },
})

const loadMidtrans = () => {
  //@see: https://api-docs.midtrans.com/#get-token
  const script = document.createElement('script')
  script.src = 'https://api.midtrans.com/v2/assets/js/midtrans.min.js'

  document.getElementsByTagName('head')[0].appendChild(script)
}

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethod)),
)
