import React, { PureComponent } from 'react'
import Iframe from 'react-iframe'

import { Modal } from 'antd-mobile'

import { ModalTitle } from '@pc/components/ModalTitle'

import Icon from '@pc/components/Icon'
import styles from './style.m.scss'
import { helpers } from '@common'
const className = helpers.classNames.react(styles)

export class MidtransIframePopup extends PureComponent {
  render() {
    const { midtransUrl, closeIframe, platform } = this.props

    return (
      <Modal
        popup
        visible={midtransUrl}
        title={<ModalTitle title={''} onClick={closeIframe} />}
        animationType="slide-up"
        // afterClose={}
        {...className('midtrans-ifram-popup')}
      >
        <Iframe url={midtransUrl} {...className('midtrans-iframe')} />
      </Modal>
    )
  }
}
